<template>
<div class='page'>
    <div class="content">
        <div class="left">WHY CHOOSE HLH</div>
         <div class="rig">
             <div class="item">
                 <div class="itemImg"><img src="../../assets/img/gou.png" alt=""></div>
                 <div class="info">Instant Quotes</div>
             </div>
             <div class="item">
                 <div class="itemImg"><img src="../../assets/img/gou.png" alt=""></div>
                 <div class="info">Always-available production capacity</div>
             </div>
             <div class="item">
                 <div class="itemImg"><img src="../../assets/img/gou.png" alt=""></div>
                 <div class="info">Easy order management </div>
             </div>
             <div class="item">
                 <div class="itemImg"><img src="../../assets/img/gou.png" alt=""></div>
                 <div class="info">Dedicated team of specialists</div>
             </div>
             <div class="item">
                 <div class="itemImg"><img src="../../assets/img/gou.png" alt=""></div> 
                 <div class="info">Most competitive prices in the market</div>
             </div>
             <div class="item">
                 <div class="itemImg"><img src="../../assets/img/gou.png" alt=""></div>
                 <div class="info">Comprehensive capabilities</div>
             </div>
             <div class="item">
                 <div class="itemImg"><img src="../../assets/img/gou.png" alt=""></div>
                 <div class="info">Fast delivery time</div>
             </div>
         </div>
    </div>
</div>
</template>
<script>
export default {
data() {
return {}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
.left{
    font-size: 56px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 43px;
}
.itemImg{
    width: 40px;
    height: 40px;
    margin-right:20px;
    /* margin-bottom: 20px; */
}
.info{
    font-size: 29px;
font-family: DengXian;
font-weight: bold;
color: #FFFFFF;
line-height: 58px;
}
img{
    width: 100%;
}
.item{
    display: flex;
    align-items:center;
}
.page{
    width: 100%;
    background: url('../../assets/img/why1.png') no-repeat;
    background-position: center !important;
    /* height: 580px; */

}
.content{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 80px 0;
    align-items: center;
}
 @media screen and (max-width: 760px) {
 .content{
        width: 90%;
        min-width: 400px;
        display: block;
       padding: 10vw 0 
    }
    .item{
        /* text-align: center; */
        
    }
    .itemImg{
        width: 30px;
        height: 30px;
    }
    .left{
        font-size:30px  ;
        text-align: center;
        margin-bottom:30px 
    }
    .info{
        font-size: 17px;
        line-height: 34px;
    }
    .rig{
        margin: auto
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
    .content{
        width: 90%;
    }
    .left{
        font-size:23px  
    }
    .info{
        font-size: 17px
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .left{
        font-size: 40px
    }
    .info{
        font-size: 25px
    }

}
@media screen and (min-width: 1350px) {
    
}
</style>