<template>
<div class='home'>


<div class="containerhome">
 <div class="swiper" id="swiper" >
    <div class="swiper-wrapper">

      <div class="swiper-slide" >
         
        <div class="banner" @click="jump('/cnc-plastic')">
            <div class="w"> 
                <div class="info">
                    <div class="title">CNC PLASTIC </div>
                    <p class="title1">-Massive Range Of Materials</p>
                    <p class="title1">-Great Surface Finishing
                    </p>
                </div>
                <div class="bannerimg">
                   <div class="bannerimg_"> <img src="../../assets/img/homeshou1.png" alt=""></div>
                 <div class="bannerimg_"> <img src="../../assets/img/homeshou2.png" alt=""></div>
                </div>
            </div> 
        </div>

      

        

     
      </div>

   <div class="swiper-slide" >
         
        <div class="banner banner2" @click="jump('/cnc-metal')">
            <div class="w"> 
                <div class="info">
                    <div class="title">CNC METAL</div>
                    <p class="title1">-High Dimensional Accuracy</p>
                    <p class="title1">-High Quality</p>
                </div>
                <div class="bannerimg">
                   <div class="bannerimg_"> <img src="../../assets/img/homeshou3.png" alt=""></div>
                 <div class="bannerimg_"> <img src="../../assets/img/homeshou4.png" alt=""></div>
                </div>
            </div> 
        </div>

      

        

     
      </div>
       

       
     
    </div>
   
 
   
  </div>
</div>
 





<videobanner></videobanner>
  <myhead :hbg="hbg"></myhead>
  <bread :bbg="bbg"></bread> 

    <services></services>
    <display></display>
    <curstomers></curstomers>

    <introdct></introdct>
    <he></he>
 <by :byData="byData"></by>
 <certification></certification>
 <part></part>
 <why></why>
<crelist></crelist>

<applications2 :ApplicationsLists="ApplicationsLists"></applications2>
 
</div>
</template>
<script>
 import Swiper from "../../../node_modules/swiper/swiper-bundle.js";
import "../../../node_modules/swiper/swiper.min.css";
 
import bread from '../../components/bread.vue'
  import applications2 from "../../components/Applications2.vue";
   import curstomers from './curstomers.vue'
     import videobanner from './videobanner.vue'
  import services from './services.vue'
  import display from './display.vue'
  import he from './he.vue'
  import introdct from './introdct.vue'
  import by from './by.vue'
import myhead from '../../components/myHead.vue'
  import part from './Part.vue'
 import why from './why.vue'
import certification from './certification.vue'
import crelist from './crelist.vue'
 
 
export default {
data() {
return {
     hbg:{
        bg:'#ffffff'

    },
    bbg:{
 bg:'#ffffff',
 color:'#000000'
    },
ApplicationsLists: {
        H1: "HIGH QUALITY PARTS EVERY TIME",
        isshow:false,
        bg:'#ffffff',
        color1:'#DA251D',
        color2:'#000000',
        data: [
          {
            img: require("../../assets/img/home1model1.png"),
            title: "QUALITY ASSURANCE",
            info:"From factory audits to part inspections, we follow strict processes to ensure your parts always meet the highest quality standards."
          },
          {
            img: require("../../assets/img/home1model2.png"),
            title: "IP PROTECTION",
            info:  "We take every measure to ensure the protection of your IP, which is why 27% of Fortune 500 companies trust us with their projects."
          },
          {
            img: require("../../assets/img/home1model3.png"),
            title: "CERTIFICATIONS",
            info: "Our manufacturing partners have all the necessary certifications for your project's needs (ISO 9001, ISO 14001, IATF 16949, ISO 27001)."
          }
        ]
      },
   byData:{
        H1:'OUR EXPERIENCE IN NUMBERS',
     
        lists:[{
            img:require('../../assets/img/sr1.png'),
            info:'12,000 sq/m Manufacturing Facility',
        },{
             img:require('../../assets/img/sr3.png'),
            info:'Experienced Engineers',
        },{
            img:require('../../assets/img/sr4.png'),
            info:'Up-to-date Machines',
        },{
             img:require('../../assets/img/sr2.png'),
            info:'Clients — Lasting relationships， from Fortune 100 to startups',
        },],
    },
}
},
methods: {
    jump(path){
         this.$router.push({ path: path }).catch(err => err);

    }
},
mounted() {


var swiper = new Swiper('#swiper', {
    loop: true,
    autoplay: {
    delay: 5000,
    // stopOnLastSlide: false,
    // disableOnInteraction: true,
    },
 
});
 
console.log(swiper);
    
 
},
  computed: {
    
  },
 
created() {
  
},
props:{},
watch:{ },
components:{
  myhead,
  services,
  display,
  curstomers,
  by,
  he,
  certification,
  part,
  why,
  applications2,
  crelist,
  introdct,
  bread,
  videobanner,
 
}
}
</script>
<style scoped lang="scss">
.page{
  width: 100%;
 
  
}
.banner{
        background: url('~@/assets/img/homebanner11.png') no-repeat;
        
  
        width: 100%;
 
        transition: 0.25s;
    
        padding-top: 70px;
        .w{
         
            display: flex;
            margin: auto;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 87px;

        }
    
        .title1{
     
        
            transition: 0.25s;
            color: #ffffff;
          
      
        }
        .info {
      
    
            font-size: 24px;
            color: #333333;
       
            .item{
                display: flex;
                align-items: center;
            
            }
            
            >.title {
           
                color: #DA251D;
           transition: 0.25s;
           font-weight: bold
            }
        }
    }
    .bannerimg_{
        margin: 0 10px;
        
    }
    .banner2{
 background: url('~@/assets/img/homebanner12.png') no-repeat;
    }
    .bannerimg_{
        max-width: 320px;
        width: 23vw;

    }
   
   img{
       width: 100%;
   }
    
@media screen and (max-width: 760px) {   //最小  min-width="330px"
.bannerimg_{
    width: 36vw;
    margin-left:0px ;
    margin-right:10px
}
    .info{
   
    }
    .w{
        width: 90%;
        min-width: 330px;
    }
    .title{
           font-size: 40px;
    font-weight: bold;
    margin-bottom: 5vw;
    } 
    .title1{
     font-size: 18px;
    // font-weight: bold;
    // width: 44vw;
    line-height: 30px;
        min-width: 300px
          // line-height: 25px;
    }
 
    .itemT{
        font-size: 14px;
    }
 
    .banner{

        
        height: 620px;
            background-position:65% 36% !important
    }
     
}
.bannerimg{
    display: flex;
    margin-top:20px
}
.Applications{
    margin-top:-80px 
}

//平板
@media screen and (min-width: 760px) and (max-width: 1020px) {


.Applications{
    margin-top:-80px 
}
    .w{
        width: 90%;
    }
    .title{
        font-size: 40px;
        margin-bottom: 3vw;
    }
    .title1{
          font-size: 22px;

    // width: 54vw;
 

    }

    .itemT{
        font-size: 22px;
    }
   
    .banner{
        height: 620px;
       background-position:65% 62%!important;
    }
 
    .info{
        // margin-top: 30px;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .Applications{
    margin-top:-40px 
}
    .w{
        width: 90%;
    }
    .title{
        font-size: 42px;
        margin-bottom: 42px; 
    }
   .title1{
           font-size: 28px;
    // width: 36vw;
    line-height: 36px;
    }
 
        .itemT{
            font-size: 24px;
        } 
        .banner{
            height: 620px;
            background-position:65% 62%!important;
        }
       
        .info{
            // margin-top: 30px;
        }
}



@media screen and (min-width: 1350px) {  //最大限制   width="1280px"
.Applications{
    margin-top:-40px 
}
    .w{
        width: 1200px;
    }
    .title{
        font-size: 42px;
        margin-bottom: 54px;
    }
    .title1{
        font-size: 24px;
    // width: 478px;
    line-height: 38px;
    }
    .itemT{
        font-size: 24px;
    }
   
        .banner{
            height: 620px;
            background-position: center !important;
        }
       
        .info{
            // margin-top: 30px;
        }
}
</style>