<template>
<div class='page'>
    <div class="content">
        <div class="H1">INTERNATIONAL CERTIFICATION</div>
        <div class="info">HLH PROVIDES ONE-STOP SOLUTIONS FROM 3D MODELING TO REAL PRODUCTION,</div>
        <div class="info">INTEGRATE GLOBAL HIGH-QUALITY RESOURCES TO PROVIDE YOU WITH PROFESSIONAL AND CONVENIENT SERVICES</div>
        <div class="list">
             <div class="item1"><img src="../../assets/img/certification1.png" alt=""></div>
             <div class="item2"><img src="../../assets/img/certification2.png" alt=""></div>
             <div class="item3"><img src="../../assets/img/certification3.png" alt=""></div>
        </div>
    </div>
</div>
</template>
<script>
export default {
data() {
return {}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
    padding: 30px 0
    /* background: #F4F4F4 */
}
img{
    width: 100%;
}
.info{
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #787878;
/* font-weight: bold; */
text-align:center;
line-height: 25px;
width: 70%;
margin: auto
}
.list{
  
    display: flex;
    justify-content: center;
    align-items: center;
    margin:60px auto;
    
}
.H1{
    font-size: 50px;
font-family: Source Han Sans CN;
font-weight: 500;
color: #000000;
line-height: 175px;
text-align: center;
}
.info:last-child{
    margin-bottom:20px 
}
.content{
    width: 1200px;
    margin: auto
}
 @media screen and (max-width: 760px) {
      .H1{
      font-size: 20px;
      line-height: 52px;
  }
  .info{
      width: 100%;
      font-size: 14px
  }
  .list{
      margin: 30px 0
  }
.content{
        width: 90%;
        min-width: 330px;
    }
   .item1{
   width: 30%;
    }
    .item2{
  width: 40%;
    }
    .item3{
  width: 30%;
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
     .H1{
    font-size: 35px;
    line-height: 90px;
  }
.content{
        width: 90%;
    }
   .item1{
   width: 30%;
    }
    .item2{
  width: 40%;
    }
    .item3{
  width: 30%;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
  
  .H1{
      font-size: 40px;
      line-height: 116px;
  }
    .content{
        width: 90%;
        
    }

 
}
@media screen and (min-width: 1350px) {
    
}
</style>