<template>
<div class='page'>
    <div class="content">
        <div class="h1">HLH MOLD TECHNOLOGY IS THE SUBSIDIARY ARM OF HLH PROTOTYPES LTD IN WUHAN</div>
      
    </div>
</div>
</template>
<script>
export default {
data() {
return {}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
    height: 160px;
    margin: auto;

    background: url('../../assets/img/he1.png') no-repeat;
    background-position: center !important;
}
.content{
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
  
    align-items: center


    
}
.h1{
    width: 71%;
    align-items: center;
    font-size: 31px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #E3E3E3;
line-height: 46px;
text-align: center;
margin: auto
}
@media screen and (max-width: 760px) {
   .content{
        width: 90%;
        min-width: 330px
    }
     .h1{
        width: 87%;
        font-size: 16px;
        line-height: 32px;
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
     .h1{
        width: 87%;
        font-size: 26px
    }
    .content{
        width: 90%;
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .h1{
        width: 87%;
        font-size: 33px
    }
    .content{
        width: 90%;
    }

}
@media screen and (min-width: 1350px) {
    
}
</style>