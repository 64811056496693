<template>
 

 <div class="page">

      
         <div class="pagebgtop">
        <div class="swiper-container11">
           <div class="swiper-wrapper">

               <div class="swiper-slide"   v-for="(item,index) in lists" :key="index">
                   <div class="contentImg"  @click="jump(item.path)">
                      <img :src="item.img" :alt=item.name>
           
                         <div class="contentImg1">
                             <div class="name"> {{item.name}}</div>
                             <div class="info"> {{item.info}}</div>
                         </div>
         
                   </div>
       
               </div>
     
             </div>
             <div class="swiper-pagination"></div>

             <div class="swiper-button-next"></div>
             <div class="swiper-button-prev"></div>

         </div>
         </div>
  
                
 </div>
 
                    
</template>
<script>
import Swiper from "../../../node_modules/swiper/swiper-bundle.js";
 import "../../../node_modules/swiper/swiper.min.css";
 
 
export default {
  data() {
    return {
      screenWidth: document.body.clientWidth,
      sum: 3,
      spaceBetween: 0,
      swiper: null,
      lists: [
        {
          name: "AEROSPACE & UAV",
          img: require("../../assets/img/crelist1.png"),
          path: "/aerospace-uav",
          info: `We offer tailored solutions to the aerospace and UAV markets enabling them to rapidly innovate new solutions. Our range of tailored services, everything from prototyping through to low/ mid volume produtin...`
        },
        {
          name: "AUTOMOTIVE",
          img: require("../../assets/img/crelist2.png"),
          path: "/automotive",
          info: `HL H work with leading automotive brands both in China and around the world. We have delivered some prototypes and parts for some of the highest profile and most desirable vehicles on the road.`
        },
        {
          name: "COMMUNICATIONS",
          img: require("../../assets/img/crelist3.png"),
          path: "/communications",
          info: `The communications industry is everywhere, and we rely on it evermore throughout our daily lives. With the emergence of loT and smart devices the depth to which it impacts our lives grows even deeper...`
        },
        {
          name: "CONSUMER PRODUCTS",
          img: require("../../assets/img/crelist4.png"),
          path: "/consumer-products",
          info: `HLH have been building prototypes and parts for the consumer products industry for years. We already work with some of the worlds leading names big and small, companies Like Whirlpool and Oculus...`
        },
        {
          name: "INDUSTRIAL",
          img: require("../../assets/img/crelist5.png"),
          path: "/industrial",
          info: `We enable you to focus on what is important for your team and your clients by offering a range of tailored solutions to the industrial and energy industries.`
        },
        {
          name: "MEDICAL",
          img: require("../../assets/img/crelist6.png"),
          path: "/medical",
          info: `The medical industry needs high quality, dependable and safe parts and products so they can deliver healthier and happier lives to all. At HLH we have been working with medical device manufacturers large and small...`
        },
        {
          name: "PRODUCT DEVELOPMENT",
          img: require("../../assets/img/crelist7.png"),
          path: "/product-development",
          info: `Industrial design and engineering consultancies are some of the most innovate and creative enterprises on the planet. You are the backbone of our business too, HLH work with product designers the world...`
        },
        {
          name: "ROBOTICS & AUTOMATION",
          img: require("../../assets/img/crelist8.png"),
          path: "/robotics-automation",
          info: `Robotics and automation are two of the fastest ! growing industries globally and there are exciting developments and challenges to be faced every day. HLH work with some of the worlds leading names, companies...`
        }
      ]
    };
  },
  methods: {
    jump(path) {
      this.$router.push({ path: path }).catch(err => err);
    }
  },
  mounted() {
    if (this.screenWidth >= 1358) {
      this.sum = 3;
      this.spaceBetween = 0;
    }
    if (this.screenWidth >= 1020 && this.screenWidth < 1350) {
      this.sum = 3;
      this.spaceBetween = 0;
    }
    if (this.screenWidth >= 760 && this.screenWidth < 1020) {
      this.sum = 2;
      this.spaceBetween = 0;
    }
    if (this.screenWidth < 760) {
      this.sum = 1;
      this.spaceBetween = 0;
    }
    
    this.swiper = new Swiper(".swiper-container11", {
      slidesPerView: this.sum, //个数
      // loop: true,
      spaceBetween: this.spaceBetween,
       pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  },
  created() {
    let that = this;
    setInterval(function() {
      that.screenWidth = document.body.clientWidth;

      window.onresize = () => {
        return () => {
          that.screenWidth = document.body.clientWidth;
        };
      };
    }, 500);
  },
  props: {},
  watch: {
    screenWidth: function() {
      if (this.screenWidth >= 1358) {
        this.sum = 3;

        this.spaceBetween = 0;
      }
      if (this.screenWidth >= 1020 && this.screenWidth < 1350) {
        this.sum = 3;
        this.spaceBetween = 0;
      }
      if (this.screenWidth >= 760 && this.screenWidth < 1020) {
        this.sum = 2;

        this.spaceBetween = 0;
      }
      if (this.screenWidth < 760) {
        this.sum = 1;

        this.spaceBetween = 0;
      }

      this.swiper = new Swiper(".pagebgtop .swiper-container11", {
        slidesPerView: this.sum, //个数
        // loop: true,
        spaceBetween: this.spaceBetween,
       pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
        navigation: {
          nextEl: ".pagebgtop .swiper-button-next",
          prevEl: ".pagebgtop .swiper-button-prev",
        },
      });
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
/deep/.swiper-slide {
  background: none;
}
.page {
  width: 100%;
}

/deep/.swiper-button-prev{
  background-image: url("../../assets/img/ril0.png");
  left: 5vw;

  position: relative !important;
  width: 60px !important;
  background-size: 100% 100%;
  height: 60px;
  top: -18.5vw;
  z-index: 9;

  //  display: none
}
/deep/.swiper-button-next {
  background-image: url("../../assets/img/ril1.png");

  position: relative !important;
  width: 60px !important;
  background-size: 100% 100%;
  height: 60px;
  right: -92vw;
  z-index: 9;
  top: -15vw;
}

.contentImg1 {
  position: absolute;
  padding-left: 24px;
  width: 90%;
  margin-top: -54%;
  font-weight: 400;
  color: #da251d;
  word-wrap: break-word;
}
.info {
  text-align: left;
  font-size: 17px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffffd9;
  line-height: 22px;
}
.name {
  text-align: left;
  font-weight: 400;
  color: #da251d;
  word-wrap: break-word;
  margin-bottom: 3vw;
  font-size: 30px;
  font-weight: bold;

  // margin-left: 20px;
}
.contentImg {
  width: 100%;
  // min-width: 400px;
  // border-radius: 10px;
  overflow: hidden;
  transition: 0.25s;
  z-index: 111;
  cursor: pointer;
}

.contentImg:hover img {
  transition: 0.5s;
  transform: scale(1.3);
}
img {
  width: 100%;
  height: 100%;
  transition: 0.2s;
}

.swiper-container {
  width: 100%;
  /* height: 300px; */
  margin: 20px auto;
  padding-bottom: 40px;
}
/deep/.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 10px;
}
/deep/.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}
/deep/ .swiper-pagination-bullet-active {
  background: #d82329;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.append-buttons {
  text-align: center;
  margin-top: 20px;
}
/deep/.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px;
}

.swiper-pagination1 {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.append-buttons a {
  display: inline-block;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}

@media screen and (max-width: 760px) {
  .contentImg1 {
    margin-top: -17vw;
  }
  .name {
    // width: 100%;
    font-size: 25px;
    margin-bottom: 6vw;
  }
  .info {
    font-size: 15px;
  }

  .contentImg1 {
    margin-top: -54%;
  }
  .swiper-button-prev {
    display: none;
    top: -50.3vw;
  }

  .swiper-button-next {
    // display: none;
    top: -39vw;
  left: 82vw
  }
}

@media screen and (min-width: 760px) and (max-width: 1020px) {
  .name {
    // width: 200px;
    font-size: 24px;
    margin-bottom: 5vw;
  }
  .info {
    font-size: 14px;
  }

  .contentImg1 {
    margin-top: -54%;
  }

  .swiper-button-prev {
    // display: none;
    top: -28vw;
  }

  .swiper-button-next {
    // display: none;
    top: -22vw;
  }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
  .contentImg1 {
    margin-top: -63%;
  }
  .name {
    // width: 200px;
    font-size: 21px;
    margin-bottom: 3vw;
  }
  .info {
    font-size: 13px;
  }

  .swiper-button-prev {
    // display: none;
    top: -21vw;
  }

  .swiper-button-next {
    // display: none;
    top: -16.5vw;
  }
}

@media screen and (min-width: 1350px) {
}
</style>