<template>
<div class='page'>
    <div class="content">
          <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in lists" :key="index">
                      <div class="item">
                      <div class="index">
                        <div class="id" >0{{index+1}}</div>
                        <div class="hebg">
                          <!-- v-if="index1==index" -->
                            <div class="he" ></div>
                        </div>
                      </div>
                      <div class="imgContent" @click="paly(index)">
                          <div class="img"> <img :src="item.img" alt=""></div>
                          <div class="imgBg"  v-if="index1!=index"> 
                              <div class="imgBgbf"> 
                                   <img src="../../assets/img/bvofang.png" alt="">
                              </div>
                            
                          </div>
                         
                      </div>

                      </div>
                     
                    </div>

                     
                </div>
           
           
            </div>
    </div>


    <div class="videoQT"  v-show="isshow"  >
    <div class="videoContentbg">
        <div class="closeBg"> <div class="close" @click="close()">×</div></div>
        
        <div class="videoContent">
               <video :muted='muted' id="videoContentHome1" ref="videoContentHome1" :autoplay='autoplay' controls :src="videoSrc"></video>
        </div>
       

    </div>

    </div>

 

    
</div>
</template>
<script>
import Swiper from "swiper";

import "../../../node_modules/swiper/swiper.min.css";
export default {
  data() {
    return {
      videoSrc:'',
      screenWidth: document.body.clientWidth,
      sum: 6,
      spaceBetween: 30,
      swiper: null,
      isshow: false,
      muted:true,


      autoplay:'autoplay',
      index1: -1,
      lists: [
        {
          img: require("../../assets/img/videobanner1.png"),
          videoSrc: 'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-jinshu1.mp4'
        },
        {
          img: require("../../assets/img/videobanner2.png"),
          videoSrc: 'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-suliao1.mp4' 

        },
        {
          img: require("../../assets/img/videobanner5.png"),
          videoSrc: 'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-jinshu5.mp4'
        },
        {
          img: require("../../assets/img/videobanner4.png"),
          videoSrc: 'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-jinshu4.mp4'
        },
        {
          img: require("../../assets/img/videobanner3.png"),
          videoSrc: 'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-jinshu2.mp4'
        },
        {
          img: require("../../assets/img/videobanner6.png"),
          videoSrc: 'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-suliao2.mp4'
        }
      ]
    };
  },
  methods: {
      close(){
      this.isshow=false
      this.index1=-1
    this.autoplay=''
 
       document.getElementById('videoContentHome1').pause()
 this.muted=true
 
     
 
      },
    paly(index) {
      this.videoSrc=this.lists[index].videoSrc
      this.index1 = index;
      this.isshow = true;
  this.autoplay='autoplay'
 
document.getElementById('videoContentHome1').play()
this.muted=false
  // this.$refs.videoContentHome1.play()
    }
  },
  mounted() {
    this.swiper = null;

    if (this.screenWidth >= 1358) {
      this.sum = 6;
      this.spaceBetween = 25;
    }
    if (this.screenWidth >= 1020 && this.screenWidth < 1350) {
      this.sum = 5;
      this.spaceBetween = 25;
    }
    if (this.screenWidth >= 760 && this.screenWidth < 1020) {
      this.sum = 4;
      this.spaceBetween = 25;
    }
    if (this.screenWidth < 760) {
      this.sum = 3;
      this.spaceBetween = 25;
    }
    if (this.screenWidth < 500) {
      this.sum = 2;
      this.spaceBetween = 25;
    }

    this.swiper = new Swiper(".content .swiper-container", {
      spaceBetween: this.spaceBetween,
      slidesPerView: this.sum //个数
    });
  
  },
  created() {
    let that = this;
    setInterval(function() {
      that.screenWidth = document.body.clientWidth;

      window.onresize = () => {
        return () => {
          that.screenWidth = document.body.clientWidth;
        };
      };
    }, 500);
  },
  props: {},
  watch: {
    screenWidth: function() {
      this.swiper = null;
      if (this.screenWidth >= 1358) {
        this.sum = 6;
        this.spaceBetween = 25;
      }
      if (this.screenWidth >= 1020 && this.screenWidth < 1350) {
        this.sum = 5;
        this.spaceBetween = 25;
      }
      if (this.screenWidth >= 760 && this.screenWidth < 1020) {
        this.sum = 4;
        this.spaceBetween = 25;
      }
      if (this.screenWidth < 760) {
        this.sum = 3;
        this.spaceBetween = 25;
      }
      if (this.screenWidth < 500) {
        this.sum = 2;
        this.spaceBetween = 25;
      }

      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: this.sum, //个数

        spaceBetween: this.spaceBetween,
 
      });
    }
  },
  components: {}
};
</script>
<style scoped>
.videoQT {
  width: 100vw;
  height: 100vh;
  min-width: 330px;

  background: #000000c7;
  position: fixed;
  top: 0;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
}
video{
    width: 100%;
    height: 100%;
    max-height: 500px;
    object-fit:contain
}
.videoContentbg{
    width: 900px;
    /* height: 600px; */
    /* background: red; */
    /* border-radius: 10px; */
    /* border: 1px solid #ffffff */

}
@keyframes myfirst
{
      
      0% {
      background: red;
       width: 0%; 
      }
     
       100% {
      background: red;
       width: 100%; 
      }
}
 
.item{
  cursor: pointer;
}
.item:hover .id{
  color: #da251d
}
.item:hover .he{
animation: myfirst 2s linear;
 
 
}
.item:hover .hebg{
  transition: 4s;
  background: #da251d

}
.closeBg{
    width: 100%;
    display: flex;
    justify-content: flex-end
}
.close{
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #ffffff;
    color: #ffffff;
    font-size: 26px;
    line-height: 26px;
    text-align: center;

}
 
.videoContent{
width: 860px;
/* height: 500px; */
background: black;
border-radius: 10px;
border: 1px solid #ffffff;
padding: 33px 
}
.page {
  width: 100%;
  /* background: red; */
  margin-top: -120px;
}

.id {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  width: 40px;
} 
.imgBg {
  background: #19191954;
  width: 100%;
  height: 90px;
  margin-top: -90px;
  display: flex;

  justify-content: center;
  align-items: center;
  position: relative;
}
.he {
  width: 0%;
  height: 100%;
  background: #da251d;
   /* animation: myfirst 2s infinite alternate; */
 
}
.index {
  display: flex;
  height: 22px;
  align-items: center;
  margin-bottom: 10px;
}
.hebg {
  width: 138px;
  height: 3px;
  border-radius: 2px;
  background: white;
}
.img {
  width: 100%;
  height: 100%;
}
img {
  width: 100%;
}
.imgBgbf {
  width: 40px;
  height: 40px;
}
.content {
  width: 1200px;
  /* background: red; */
  height: 120px;
  margin: auto;
  overflow: hidden;
}
.imgContent {
  width: 178px;
  height: 90px;
  background: red;
cursor: pointer;
}
@media screen and (max-width: 500px) {
  .content {
    /* width: 600px; */

    width: 90%;
  }
   .videoContentbg{
      width: 100%;
  }
  .closeBg{
      margin-bottom:20px ;
      width: 95%
  }
  
 
  .videoContent{
      width: 100%;
       
   
    background: black;
    border-radius: 0px;
    border:none;
    padding: 10px;
     
     
  }
}
@media screen and (min-width: 500px) and (max-width: 760px) {
  .content {
    /* width: 600px; */

    width: 90%;
  }
   .videoContentbg{
      width: 100%;
  }
  .closeBg{
      margin-bottom:20px ;
       width: 98%
  }
 
  .videoContent{
      width: 100%;
     
     
  }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
  .content {
    width: 90%;
  }
  .videoContentbg{
      width: 90%;
  }
  .closeBg{
      margin-bottom:20px 
  }
 
 
  .videoContent{
      width: 100%;
     
     
  }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
  .content {
    width: 90%;
  }
}
@media screen and (min-width: 1350px) {
}
</style>