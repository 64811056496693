<template>
<div class='page'>
    <div class="content">
        <div class="model">
            <div class="he"></div>
            <div class="name">PRODUCT DISPLAY</div>
            <div class="he"></div>
        </div>
        <div class="list">
            <div class="item"><img src="../../assets/img/displayitem1.png" alt=""></div>
            <div class="item"><img src="../../assets/img/displayitem2.png" alt=""></div>
            <div class="item"><img src="../../assets/img/displayitem3.png" alt=""></div>
            <div class="item"><img src="../../assets/img/displayitem4.png" alt=""></div>
            <div class="item"><img src="../../assets/img/displayitem5.png" alt=""></div>
            <div class="item"><img src="../../assets/img/displayitem6.png" alt=""></div>
            <div class="item"><img src="../../assets/img/displayitem7.png" alt=""></div>
            <div class="item"><img src="../../assets/img/displayitem8.png" alt=""></div>
        </div>
        
    </div>
</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},
  components: {}
};
</script>
<style scoped>
img {
  width: 100%;
}
.name {
  width: 20%;
  background: #da251d;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  min-width: 380px
}
.list {
  /* margin: 20px 0; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.he {
  width: 40%;
  height: 1px;
  background: #da251d;
}
.model {
  width: 100%;
  padding: 30px 0;
  /* margin: auto; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page {
  width: 100%;
  background: url("../../assets/img/display1.png") no-repeat;
  /* height: 550px; */
background-position: center !important;
}
.item {
  max-width: 280px;
  margin-bottom:20px
}
.content {
  width: 1200px;
  margin: auto;
}
@media screen and (max-width: 760px) {
    .name{
        min-width: 160px
    }
          .content{
        width: 90%;
        min-width: 330px
    }
    .item{
        /* width: 48%; */
        max-width: 48%;
        

    }
  

    .page{
     background: url("../../assets/img/display2.png") no-repeat;
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
       .content{
        width: 90%;
    }
    .item{
        width: 23%;

    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .item{
        width: 23%;

    }
}
@media screen and (min-width: 1350px) {
}
</style>