<template>
<div class='page'>
    <div class="pagebg">
    <div class="content">
        <div class="left"><img src="../../assets/img/introdct1.png" alt=""></div>
        <div class="rig">
             <div class="Name">COMPANY INTRODUCTION</div>
        <div class="Title">WE MAKE THINGS FOR YOU ！</div>
        <div class="info">At HLH, we make things for you. Rapid products and parts delivered fast. First class prototypes that look like production parts.Flexible project solutions that fit your specific project requirements. <br><br>Our MOQ starts at 1 for prototypes and typically<br> goes up to 100,000 parts (plastic, metal, composites),<br> anywhere in this range is what we would consider low-volume manufacturing.</div>
         
        <!-- <div class="next">NEXT PAGE -></div> -->
        </div>
       </div>
    </div>
</div>
</template>
<script>
export default {
data() {
return {}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
img{
    width: 100%;
    height: 100%;
}
.pagebg{
    background: #232323;
    /* background: url('../../assets/img/introdct1.png') no-repeat */
}
.next{
    font-size: 18px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 14px;
}
.left{
  width: 51%;
    
}
.Name{
    font-weight: bold
 
}

.rig{
    /* width: 49%; */
    padding-left:5vw 
}
.page{
width: 1920px;
 
background: #232323;
 


}
.info{
    /* width: 31%; */
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #FFFFFF;
line-height: 24px;
max-width: 480px;
margin-bottom:1vw 
}
.content{
     width:100%;
     margin: auto;
    /* height: 520px; */
 
 align-items: center;
    display: flex;
    /* justify-content: flex-end; */
}
.Name{
    font-size: 18px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
line-height: 14px;
margin-bottom:10px 
}
.Title{
    font-size: 24px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
 
line-height: 30px;
margin-bottom:2vw 
}
 @media screen and (max-width: 760px) {
     .content{
display: block;
 

     }
     .left{
         width: 100%;
         margin: auto
     }
     
     .rig{
         width: 90%;
         padding: 6vw 0;
         margin: auto
     }
     
.Title{
    font-size:22px
}
.next{
    font-size: 16px;
    margin: 5vw 0
}
.Name{
    font-size: 18px
}
.info{
    font-size: 14px;line-height: 25px;
}

}
@media screen and (min-width: 760px) and (max-width: 1020px) {
    

    .left{
        height:33vw;
    }

.Title{
    font-size:19px
}
.next{
    font-size: 12px
}
.Name{
    font-size: 14px
}
.info{
    font-size: 12px;line-height: 17px;
}
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {

.Title{
    font-size:19px
}
.next{
    font-size: 12px
}
.Name{
    font-size: 14px
}
.info{
    font-size: 12px;line-height: 17px;
}
}
@media screen and (min-width: 1350px) {
    
}
</style>