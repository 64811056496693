<template>
<div class='page'>
    <div class="content">
        <div class="H1">HOW TO ORDER A CNC MACHINING PART</div>
        <div class="listbg">
        <div class="list">

            <div :class="'itembg itembg'+index" v-for="(item,index) in list" :key="index">
                <div class="item"> 
                    
                    <div :class="'itemImg itemImg'+index"><img :src="item.img" alt=""></div>
                       <div :class="'itemx itemx'+index" v-if="index!=list.length-1">></div>
                 
                </div>
                 <div :class="'info info'+index  ">{{item.info}}</div>
            </div>
        </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
data() {
return {
    list:[{
        info:'Sent your 3D or 2D files',
        img:require('../../assets/img/Part1.png'),
    },{
        info:'tell us material, quantity & finish',
        img:require('../../assets/img/Part2.png'),
    },{
        info:'Get an instant quote & order',
        img:require('../../assets/img/Part3.png'),
    },{
        info:'Let us take care of production',
        img:require('../../assets/img/Part4.png'),
    },{
        info:'Receive your products',
        img:require('../../assets/img/Part5.png'),
    },]
}
},
methods: {},
mounted() {},
created() {},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
img{
    width: 100%
} 
.itemImg{
    max-width: 210px
}
.itemImg4{
    max-width: 148px
}
.itemx{
    font-size: 39px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #000000;
line-height: 31px;
margin-top:-30px 
}
 
.item{
display: flex;
align-items: center
}
.list{
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
    padding: 70px 0;
    padding-bottom:35px 
} 
.info{
    font-size: 16px;
font-family: Source Han Sans CN;
font-weight: 400;
height: 60px;
color: #FFFFFF;
line-height: 24px;
width: 68%;
margin-top:23px 
}
.H1{
font-size: 36px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #FFFFFF;
line-height: 30px;
text-align: center
}
 
.page{
    width: 100%;
    padding: 40px 0;
    background: #737373
}
.content{
    width: 1200px;
    margin: auto
}
 @media screen and (max-width: 760px) {
 
 .H1{
     font-size: 25px
 }
  
  .info4{
      width: 180px;
  }
  .itemx{
      font-size: 30px
  }
    
 .itembg{
        width: 22%;
          margin-bottom: 27px;
    }
    .listbg{
        width: 100%;
        /* background: red; */
        overflow-x: scroll;
          border-right: 1px solid #ffffff1c;
    }
    .list{
padding: 33px 0;
        padding-bottom:0px ;
        
        width: 1000px;
    }
   
    .itemx{
        margin-left: 20px;
    margin-right: 20px;
    }
    .content{
        width: 90%;
    }
    .itemImg{
        max-width:100% ;
    }
    .itembg:last-child{
        max-width:10%;
 

    }
    .info{
       font-size: 14px
    }
      .itemImg2{
        max-width: 70%
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
    
  
 .H1{
     font-size: 25px
 }
  
  .info4{
      width: 180px;
  }
  .itemx{
      font-size: 30px
  }
    
 .itembg{
        width: 22%;
          margin-bottom: 27px;
    }
    .listbg{
        width: 100%;
        /* background: red; */
        overflow-x: scroll;
             border-right: 1px solid #ffffff1c;
    }
    .list{
padding: 33px 0;
        padding-bottom:0px ;
        
        width: 1000px;
    }
   
    .itemx{
        margin-left: 20px;
    margin-right: 20px;
    }
    .content{
        width: 90%;
    }
    .itemImg{
        max-width:100% ;
    }
    .itembg:last-child{
        max-width:10%;
 

    }
    .info{
       font-size: 14px
    }
      .itemImg2{
        max-width: 70%
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .itembg{
        width: 20%;
      
    }
    .content{
        width: 90%;
    }
    .itemImg{
        max-width:100% ;
    }
    .itembg:last-child{
        max-width:13%;
 

    }
    .info4{
        width: 180px;
    }

}
@media screen and (min-width: 1350px) {
    
}
</style>