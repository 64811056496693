<template>
<div class='page'>
    <div class="content">
        <div class="H11">OUR SERVICES</div>
        <div class="info">Prototypes that look like production parts. Flexible project solutions that fit your specific project requirements.</div>
     <div class="metallist">
         <div class="metalitem" v-for="(item,index) in lists" :key="index">
             <div class="top" @click="isshowfun1(index)" v-show="!item.paly" >
                 <div class="topImg">
                     <img :src="item.img" alt="">
                 </div>
                  <div class="topTitle">
                      <div class="topTitle_">
                            <div class="h1">{{item.H1}}</div>
                            <div class="he"></div>
                            <div class="info1">{{item.info}}</div>
                            <div class="more">MORE</div>
                      </div>
                  </div>
                   <div class="topTitleImg"><div class="topTitleImg_"><img src="../../assets/img/bvofang.png" alt=""></div></div>
             </div>
             <div class="till" v-show='item.paly'><video :id="'video'+index"  :ref="'video'+index" :muted='muted'   :autoplay="autoplay1" controls :src="item.videosrc"></video></div>
             </div>

 
     </div>


     
    </div>
</div>
</template>
<script>
export default {
data() {
return {
    isshow1:true,
    autoplay1:'',
    muted:true,
    lists:[{
        H1:'CNC Metal',
        img:require('../../assets/img/services11.png'),
        info:'High machining accuracy and stable machining quality',
        videosrc:'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-jinshu2.mp4',
        paly:false,
        autoplay1:''
    },{
        H1:'CNC Plastic',
        img:require('../../assets/img/services22.png'),
        info:'Massive range of plastic materials',
        videosrc:'https://hlhwuhan-cnc.oss-eu-central-1.aliyuncs.com/vedio/o-cnc-suliao3.mp4',
        paly:false,
        autoplay1:''
    },]
}
},
methods: {
    isshowfun1(index){
    this.isshow1=false
 for(var i=0 ;i<this.lists.length;i++){
     this.lists[i].paly=false
      this.lists[i].autoplay1=''

 }

    this.lists[index].paly=true
    this.lists[index].autoplay1='autoplay'
    if(index==0){
        
document.getElementById('video0').play()
document.getElementById('video1').pause()

 

    }else{
 
document.getElementById('video1').play()
document.getElementById('video0').pause()
    }
       this.muted=false
   
}, 
},

mounted() { 
     
      document.getElementById('video0').addEventListener('ended', () => { //该视频播放是否已结束
              this.lists[0].paly=false
              this.muted=true
      }, false)

        document.getElementById('video1').addEventListener('ended', () => { //该视频播放是否已结束
         this.lists[1].paly=false
          this.muted=true
      }, false)
 


           
 
   
 
 
},
created() {
            //  console.log( this.$refs.video1);
            //  this.$refs.video1.addEventListener('play',this.handlePlay())
            //  this.$refs.video1.addEventListener('playing',this.handlePlaying())
            //  this.$refs.video1.addEventListener('waiting',this.handleWaiting())
            //  this.$refs.video1.addEventListener('pause',this.handlePause())
            //  this.$refs.video1.addEventListener('ended',this.handleEnded())
},
props:{},
watch:{ },
components:{}
}
</script>
<style scoped>
.page{
    width: 100%;
}
.metallist{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 50px 0
}
video{
    width: 100%;
    height: 100%;
}
img{
    width: 100%;
}
.more{
    width: 130px;
height: 46px;
margin: auto;
  border: 1px solid #F9F8F9;
 margin: 40px auto;
border-radius: 10px;
color: #DA251D;
line-height: 46px;
}
.topTitle_{
    width: 50%;
}
.till{
    position:relative;
    text-align: center;
 

}
.h1{
font-size: 30px;
font-family: Source Han Sans CN;
font-weight: 800;
color: #DA251D;
}
.he{
    width:117px ;
    height: 1px;
    background: #ffffff;
    margin: 23px auto;
}
.info1{
    font-size: 18px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #F9F8F9;
opacity: 0.8;
}

.topTitle{
    width: 100%;
    height: 100%;
    margin-top:-420px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* opacity: 0.7; */
     
 
}
.topTitleImg{
width: 100%;
height: 100%;
    margin-top: -420px;
 
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
 
 cursor: pointer;
    
}
.topTitleImg_{
    width: 152px;
    height: 152px;
}
.metalitem{
    width: 48%;
    height: 48.5vh;
    max-height: 420px;
    background: black
}
.content{
    width: 1200px;
    margin: auto

}

.topImg{
    height: 100%;
}
.till,.top{
    height: 100%;
    
}
.info{
    width: 70%;
    margin: auto;
    font-size: 17px;
font-family: Source Han Sans CN;
font-weight: 400;
color: #787878;
line-height: 25px;
text-align: center;
}
.H11{
    font-size: 34px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #DA251D;
line-height: 30px;
text-align: center;padding: 20px 0
}
 @media screen and (max-width: 760px) {
 .content{
     min-width: 330px;
     width: 90%;
    }
    .metallist{
        display: block
    }
    .metalitem{
        width: 100%;
        margin-bottom:60px 
    }
      .H11{
font-size: 25px;
padding-bottom: 20px;
    }
    .info{
        width: 100%;
        font-size: 15px
   
    }
    
    

.metallist{
    display: flex;
    justify-content: center
}

.h1{
    font-size: 24px
}
.he{
    width:12vw;
}
.info1{
    font-size: 14px
}
.topTitleImg_{
    width: 100px;
    height: 100px;
}
.more{
    width: 90px;
    height: 30px;
    line-height: 30px;
    font-size: 14px

}

.metalitem{
  max-width: 420px;
  min-width: 330px;
  height: 250px;
  /* background: none */
    
}
img{
    height: 100%;
    object-fit:cover;
}
.topTitleImg,.topTitle{
     max-width: 420px;
     margin-top:-250px; 
     height: 250px
}
 
.topImg{
    max-width: 420px
   
}


}
@media screen and (min-width: 760px) and (max-width: 1020px) {
  .content{
        width: 90%;
    }
    .H11{
font-size: 29px
    }
    .info{
        width: 100%;
        font-size: 15px
   
    }

.more{
    font-size: 14px;
    width: 91px;
    height: 33px;
    margin: 16px auto;
    line-height: 33px;
}
    
.topTitle{
    margin-top:-30.5vw; 
}

.topTitleImg{
     margin-top:-30.5vw; 
}
.metalitem{
    height: 30.5vw;
}
.topTitleImg_{
  width: 10vw;
    height: 10vw;
}
    
    .h1{
        font-size: 21px
    }
    .he{
            width: 7vw;
            margin: 2vw auto;
    }
    .info1{
        font-size: 13px
    }
    .more{
        
    }
    .info{
        font-size: 17px
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }

.topTitle{
    margin-top:-30.5vw; 
}

.topTitleImg{
     margin-top:-30.5vw; 
}
.topTitleImg_{
  width: 10vw;
    height: 10vw;
}
.metalitem{
    height: 30.5vw;
}
}
@media screen and (min-width: 1350px) {
    
}

</style>