<template>
<div class='page'>
    <div class="content">
        <div class="model">
        
            <div class="name">SATISFIED CUSTOMERS</div>
              <div class="info">We are still keeping good and lasting relationships with these customers</div>
          
        </div>
        <div class="list">
            <div class="item"><img src="../../assets/img/curstomers1.png" alt=""></div>
            <div class="item"><img src="../../assets/img/curstomers2.png" alt=""></div>
            <div class="item"><img src="../../assets/img/curstomers3.png" alt=""></div>
            <div class="item"><img src="../../assets/img/curstomers4.png" alt=""></div>
            <div class="item"><img src="../../assets/img/curstomers5.png" alt=""></div>
            <div class="item"><img src="../../assets/img/curstomers6.png" alt=""></div>
            <div class="item"><img src="../../assets/img/curstomers7.png" alt=""></div>
            <div class="item"><img src="../../assets/img/curstomers8.png" alt=""></div>
        </div>
        
    </div>
</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  created() {},
  props: {},
  watch: {},
  components: {}
};
</script>
<style scoped>
img {
  width: 100%;
}
.name {
  font-size: 36px;
font-family: Source Han Sans CN;
font-weight: bold;
color: #A9241F;
line-height: 30px;
text-align: center;
margin-bottom:20px 
}
.info{
    font-size: 16px;
    text-align: center;
font-family: Source Han Sans CN;
font-weight: 400;
color: #787878;
}
.list {
  /* margin: 20px 0; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.he {
  width: 40%;
  height: 1px;
  background: #da251d;
}
.model {
  width: 100%;
  padding: 40px 0px;
  /* margin: auto; */
  /* display: flex; */
  /* justify-content: space-between; */
  align-items: center;
}
.page {
  width: 100%;
  background: url("../../assets/img/curstomersbg1.png") no-repeat;
  background-position: center !important;
  /* height: 550px; */
  /* margin-bottom:50px  */
}
.item {
  width: 25%;
  max-width: 220px;
  margin: 0 2%;

  margin-bottom:30px
}
.content {
  width: 1200px;
  margin: auto;
}
@media screen and (max-width: 760px) {
    .name{
        min-width: 160px
    }
          .content{
        width: 90%;
        min-width: 330px
    }
    .item{
        /* width: 48%; */
        max-width: 220px;

        width: 42%;

    }
      .list{
      width: 82%;
      margin: auto
 
    }

    .page{
     background: url("../../assets/img/curstomersbg2.png") no-repeat;
    }
      .name{
        font-size: 30px
    }
    .info{
        font-size: 15px
    }
}
@media screen and (min-width: 760px) and (max-width: 1020px) {
       .content{
        width: 90%;
    }
    .item{
        width: 23%;

    }  .name{
        font-size: 30px
    }
}
@media screen and (min-width: 1020px) and (max-width: 1350px) {
    .content{
        width: 90%;
    }
    .item{
        width: 23%;

    }
    .name{
        font-size: 30px
    }
}
@media screen and (min-width: 1350px) {
}
</style>